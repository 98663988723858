import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Ganges",
  "date": "2022-08-23T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Rather than speaking about the geometrical or historical aspects of Ma Ganga, here I portray a different perspective of the Goddess River, which I experienced Myself. As long as I was able to feel her, I'm pretty sure anyone can experience the beauty of Ma.`}</p>
    <p>{`Ganga is considered holy due to her tremendous power to purify one's soul and remove sins( which incl all the accumulated Karmas of Past/Present life ). There are numerous stories that exist with respect to the origin of Ganga, its divinity and its sacredness. The Vedas and Upanishads have praised the power of the Ganga to the sky.`}</p>
    <p>{`She is the only river that flows in all three directions; Heaven / Earth / Hell. Hence she is also called Tripathaga. Not to mention that Ganga is mentioned 4 times in the Rigveda, 2 times as Jahnavi & 2 times as Ganga.`}</p>
    <p>{`Ganga is also called Jahnavi, daughter of Jahnu; There is only very little information about Rishi Jahnu, the sage who drank up all the Ganga's water to punish her for disturbing his penance. Later on, he released Ganga through his Ears, hence the "daughter of Jahnu".`}</p>
    <p>{`Jahnavi is also portrayed as Shimshumara, most probably representing Gavialis gangeticus `}{`[ crocodile ]`}{` or River dolphin. However Ganga Ma is known to be traveling on top of crocodiles, hence it makes sense. According to Vaishnavas, Shimshumara is shown as a combination of Porpoise/Dolphin/Scorpion which is a cosmic form of Vishnu upon whom rests the stars and heavenly bodies.`}</p>
    <p>{`I believe Ganga symbolizes the spiritual wisdom, which eternally flows towards the highest state, and also can be considered as the ever-flowing Prana inside the body. Prana also descends from the Supreme Brahma, and enters the body of man via Breath and splits into seven breaths. Similarly, Ganga also splits into seven streams after falling on Shiva's head.`}</p>
    <p>{`She is also described as a beautiful woman, wearing a white sari (sometimes red) and sitting on a lotus flower, with a crocodile (Gavialis ?) (dolphin ?) waiting at her feet and ready to serve as her vehicle. She has four hands, holding in one a lotus, in another a water pot and her two remaining hands are in the positions of blessings.`}</p>
    <p>{`Gangge Ca Yamune Chaiva Godaavari Sarasvati
Narmade Sindhu Kaaveri JaleAsmin Sannidhim Guru`}</p>
    <p>{`While listening to the "invocation to water by Rara Avis" I had no idea about the ecstatic feeling I was gonna get. Fast forward, I landed in Haridwar to witness this majestic beauty, thereby realizing the magnitude of her. Since then Ganga was always beside me, providing unparalleled love and caring, I never felt this much empathy towards anything. For me Ganga resides everywhere, the entire Earth, even our body is comprised of Ganga upto(70%).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      